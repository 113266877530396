const mutations = {
  SET_INBOX(state, inbox) {
    state.inbox = inbox;
  },
  SET_UNSUBSCRIBER(state, unsubscriber) {
    state.unsubscriber = unsubscriber;
  },
  UPDATE_INBOX(state, inbox) {
    state.inbox = inbox;
  },
  UPDATE_INBOXES(state, inbox) {
    if (state.inboxes.length > 0) {
      const inboxExists = state.inboxes.some((item) => item.id === inbox.id);
      if (inboxExists) {
        state.inboxes.forEach((item, index) => {
          if (item.id === inbox.id) {
            state.inboxes[index] = inbox;
            if (item.EstadoDaConexao !== inbox.EstadoDaConexao) {
              state.inboxCounter++;
            }
          }
        });
      } else {
        state.inboxes.push(inbox);
      }
    } else {
      state.inboxes.push(inbox);
    }
  },
  CLEAR_INBOX(state) {
    state.inbox = null;
  },
  CLEAR_INBOXES(state) {
    state.inboxes = [];
  },
};

export default mutations;