var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-qrcode-list"},[_vm._m(0),_vm._v(" "),(
        !_vm.qrCodeImg ||
        _vm.estadoDaConexao === 'isLogged' ||
        _vm.estadoDaConexao === 'isReconnect' ||
        !_vm.estadoDaConexao ||
        _vm.refreshQrCode ||
        _vm.loadingUpdateInbox ||
        _vm.loadingCreateInbox ||
        _vm.loadingStatus
      )?_c('div',{staticClass:"qrcode"},[_c('img',{staticClass:"qrcode-fake",attrs:{"src":require("dashboard/assets/images/qrCodeFake.png"),"alt":"qrcode"}}),_vm._v(" "),(
          _vm.loadingUpdateInbox ||
          !_vm.qrCodeImg ||
          _vm.loadingCreateInbox ||
          !_vm.estadoDaConexao ||
          _vm.estadoDaConexao === 'isReconnect' ||
          _vm.loadingStatus
        )?_c('div',{staticClass:"qrcode-bola"},[_c('svg',{staticClass:"qrcode-loading",attrs:{"viewBox":"0 0 24 24"}}),_vm._v(" "),_c('span',[_vm._v("Carregando...")])]):(
          _vm.refreshQrCode && !_vm.loadingUpdateInbox && !_vm.loadingCreateInbox
        )?_c('div',{staticClass:"qrcode-bola",staticStyle:{"cursor":"pointer"},on:{"click":_vm.updateQrCode}},[_c('img',{attrs:{"src":require("dashboard/assets/images/Shape.svg")}}),_vm._v(" "),_c('span',[_vm._v("Clique para recarregar")])]):(_vm.estadoDaConexao === 'isLogged')?_c('div',{staticClass:"qrcode-bola"},[_c('img',{attrs:{"src":require("dashboard/assets/images/Vector.svg")}}),_vm._v(" "),_c('span',[_vm._v("Conectado")])]):_vm._e()]):_c('div',{staticClass:"qrcode"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.qrCodeImg,"alt":"qrcode"}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"font-normal text-xl pb-2.5"},[_vm._v("\n        Para vincular seu contato com o Zlink:\n      ")]),_vm._v(" "),_c('ol',{staticClass:"font-normal text-sm pb-2.5"},[_c('li',[_vm._v("1 - Abra o Whatsapp no seu celular.")]),_vm._v(" "),_c('li',[_vm._v("\n          2 - Toque em \"Mais opções\" ou \"Configurações\", depois em \"Aparelhos\n          conectados\".\n        ")]),_vm._v(" "),_c('li',[_vm._v("3 - Toque em \"Conectar um aparelho\".")]),_vm._v(" "),_c('li',[_vm._v("4 - Aponte a câmera do seu celular e leia o Qrcode ao lado.")])])])
}]

export { render, staticRenderFns }