import actions from './actions';
import mutations from './mutations';

const state = {
  user: null,
  inboxBanner: {
    exibirBannerStatusDisconnected: false,
    id: null,
  },
  telephonyAll: [],
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
};
