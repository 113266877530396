<template>
  <div class="banner background-banner">
    <span class="banner-message">
      Sua caixa de entrada está desconectada.
      <router-link :to="addAccountScoping(`settings/inboxes/${inboxId}`)">
        <a rel="noopener noreferrer nofollow"> Conectar novamente </a>
      </router-link>
    </span>
  </div>
</template>
<script>
import accountMixin from '../../mixins/account';

export default {
  mixins: [accountMixin],
  computed: {
    inboxId() {
      return this.$store.state.avonale.inboxBanner.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  color: var(--white);
  font-size: var(--font-size-mini);
  padding: var(--space-slab) var(--space-normal);
  justify-content: center;
  position: sticky;

  &.primary {
    background: var(--w-500);
    .banner-action__button {
      color: var(--white);
    }
  }

  &.secondary {
    background: var(--s-200);
    color: var(--s-800);
    a {
      color: var(--s-800);
    }
  }

  &.alert {
    background: var(--r-400);
  }

  &.warning {
    background: var(--y-600);
    color: var(--y-500);
    a {
      color: var(--y-500);
    }
  }

  &.gray {
    background: var(--b-500);
    .banner-action__button {
      color: var(--white);
    }
  }

  a {
    margin-left: var(--space-smaller);
    text-decoration: underline;
    color: var(--white);
    font-size: var(--font-size-mini);
  }

  .banner-action__button {
    margin: 0 var(--space-smaller);

    ::v-deep .button__content {
      white-space: nowrap;
    }
  }

  .banner-message {
    display: flex;
    align-items: center;
  }
}
.background-banner {
  background: #ff382d !important;
}
</style>