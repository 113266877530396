// import { getCookie } from '../../../dashboard/helper/utils';
import avonaleAPIHelper from '../../helper/avonaleAPIHelper';
import constants from '../../resources/constants';
import axios from 'axios';
// import { refreshToken } from '../../../../javascript/dashboard/api/avonale/connectService';

const API = axios.create({
  baseURL: constants.telephonyServerAddress,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    crossdomain: true,
    'Access-Control-Allow-Origin': '*',
  },
});

API.interceptors.request.use(avonaleAPIHelper, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// let isAlreadyFetchingAccessToken = false;
// let subscribers = [];

// const onAccessTokenFetched = accessToken => {
//   subscribers = subscribers.filter(callback => callback(accessToken));
// };

// const addSubscriber = callback => {
//   subscribers.push(callback);
// };

// API.interceptors.response.use(
//   response => response,
//   error => {
//     const { config, response } = error;
//     const originalRequest = config;
//     if (response && response.status === 401) {
//       if (!isAlreadyFetchingAccessToken) {
//         isAlreadyFetchingAccessToken = true;
//         const refresh = getCookie('avonaleRefreshToken');
//         const token = getCookie('avonaleToken');

//         const domain = process.env.VUE_APP_EMBEDED_DOMINIO_URL
//           ? `; domain=${process.env.VUE_APP_EMBEDED_DOMINIO_URL}`
//           : '';
//         refreshToken(refresh, token)
//           .then(data => {
//             isAlreadyFetchingAccessToken = false;
//             // Update avonale in Cookie
//             document.cookie = `avonaleToken=${data.token}; path=/` + domain;
//             document.cookie =
//               `avonaleRefreshToken=${data.refreshToken}; path=/` + domain;
//             document.cookie =
//               `avonaleExpiresAt=${data.expiresAt}; path=/` + domain;

//             onAccessTokenFetched(data.token);
//           })
//           .catch(() => {
//             document.cookie = `loginPage=true; path=/` + domain;
//           });
//       }

//       const retryOriginalRequest = new Promise(resolve => {
//         addSubscriber(accessToken => {
//           originalRequest.headers.Authorization = `Bearer ${accessToken}`;
//           resolve(API(originalRequest));
//         });
//       });

//       return retryOriginalRequest;
//     }

//     return Promise.reject(error);
//   }
// );

async function openCall(userAvonaleId, telephonyId, destinationNumber) {
  return API.post(
    `/api/Telefonia/VoipDoBrasil/AbrirChamada?usuarioId=${userAvonaleId}&telefoniaId=${telephonyId}&numeroDestino=${destinationNumber}`
  );
}

async function getTelephonyUser(avonaleUserId) {
  const response = await API.get(
    `/api/UsuarioTelefonia/ObterTelefoniasUsuario?usuarioId=${avonaleUserId}`
  );
  return response.data;
}
export { openCall, getTelephonyUser };
