import { LocalStorage } from 'shared/helpers/localStorage';
import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';
import { emitter } from 'shared/helpers/mitt';
import { BUS_EVENTS } from 'shared/constants/busEvents';
const setDarkMode = theme => {
  const frameElement = document.getElementById(`csml-editor--frame`);

  // eslint-disable-next-line no-unused-expressions
  frameElement
    ? frameElement.contentWindow.postMessage(
        { theme: theme === 'auto' ? 'system' : theme },
        '*'
      )
    : null;
  // eslint-disable-next-line no-unused-expressions
  emitter.emit(BUS_EVENTS.CHANGE_THEME, {
    theme: theme === 'auto' ? 'system' : theme,
  });
};

export const setColorTheme = isOSOnDarkMode => {
  const selectedColorScheme =
    LocalStorage.get(LOCAL_STORAGE_KEYS.COLOR_SCHEME) || 'auto';
  if (
    (selectedColorScheme === 'auto' && isOSOnDarkMode) ||
    selectedColorScheme === 'dark'
  ) {
    document.body.classList.add('dark');
    document.documentElement.setAttribute('style', 'color-scheme: dark;');
    setDarkMode('dark');
  } else {
    document.body.classList.remove('dark');
    document.documentElement.setAttribute('style', 'color-scheme: light;');
    setDarkMode('light');
  }
};
