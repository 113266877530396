import axios from 'axios';
import constants from '../../resources/constants';
import avonaleAPIHelper from '../../helper/avonaleAPIHelper';

const API = axios.create({
  baseURL: constants.chatpierServerAddress,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    crossdomain: true,
    'Access-Control-Allow-Origin': '*',
  },
});

API.interceptors.request.use(avonaleAPIHelper, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

async function createAutomation(inboxId, userId, accountId) {
  return API.post(
    `/api/Chatpier/Chatlead/Criar/${inboxId}/${userId}/${accountId}`
  );
}

async function deleteAutomation(inboxId, userId, accountId) {
  return API.delete(
    `/api/Chatpier/Chatlead/Remover/${inboxId}/${userId}/${accountId}`
  );
}

async function generatePresignedUrl(name, fileType) {
  return API.post('/api/Chatpier/FileRepository/GerarPreSignedUrl', {
    nome: name,
    tipoDoArquivo: fileType,
  });
}

async function getKanbanBoards(accountId){
  return API.get('/api/Quadros/ListarQuadros', {
    params: {
      accountId,
    },
  });
}

async function createKanbanBoard(payload) {
  return API.post('/api/Quadros/InserirQuadro', payload);
}

async function updateKanbanBoard(payload) {
  return API.put('/api/Quadros/EditarQuadro', payload);
}

async function deleteKanbanBoard(boardId) {
  return API.delete('/api/Quadros/DeletarQuadro', {
    params: {
      quadroId: boardId,
    }
  });
}

export { 
  createAutomation, 
  deleteAutomation,
  generatePresignedUrl,
  getKanbanBoards,
  createKanbanBoard,
  updateKanbanBoard,
  deleteKanbanBoard,
};
