import Vue from 'vue';

export default {
  SET_DRAGGED_CONVERSATION($state, conversation){
    Vue.set($state, 'draggedConversation', conversation);
  },
  SET_DRAGGED_CONVERSATION_GROUP($state, {type, id}){
    Vue.set($state, 'draggedConversationGroup', {type, id});
  },
  SET_REMOVE_DRAGGED_CONVERSATION_FUNCTION($state, removeDraggedConversation){
    Vue.set($state, 'removeDraggedConversation', removeDraggedConversation);
  },
  CLEAR_ALL_INFO($state) {
    Vue.set($state, 'draggedConversation', null);
    Vue.set($state, 'draggedConversationGroup', null);
    Vue.set($state, 'removeDraggedConversation', () => {});
  }
}