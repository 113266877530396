/**
 * Verifica se a string contém caracter especial ou não.
 * @param {string} value
 * @returns {boolean}
 */
export const regexWithoutSpecialCharacters = value => {
  const regex = /^[A-Za-z0-9\s-]+$/;
  return regex.test(value);
};
export const validateTime = value => {
  const regex = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$|^$/;
  return regex.test(value);
};
