<template>
  <div class="wizard-body small-9 columns">
    <page-header
      header-title="Canal de Classificados"
      header-content="Integre sua caixa de entrada de classificados."
    />
    <form class="row" @submit.prevent="createChannel()">
      <div class="medium-8 columns">
        <label :class="{ error: $v.channelName.$error }">
          {{ $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.CHANNEL_NAME.LABEL') }}
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
            "
            @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">{{
            $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.CHANNEL_NAME.ERROR')
          }}</span>
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.email.$error }">
          {{ $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.EMAIL.LABEL') }}
          <input
            v-model.trim="email"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.EMAIL_CHANNEL.EMAIL.PLACEHOLDER')"
            @blur="$v.email.$touch"
          />
        </label>
        <p class="help-text">
          {{ $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.EMAIL.SUBTITLE') }}
        </p>
      </div>

      <div class="medium-12 columns">
        <woot-submit-button
          :loading="loading"
          button-text="Criar canal de classificados"
        />
      </div>
    </form>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { required, email } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
import { regexWithoutSpecialCharacters } from '../../../../../helper/regex';

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      channelName: '',
      email: '',
      alertMessage: '',
      loading: false,
      inboxId: null,
    };
  },
  computed: {
    userLoggedId() {
      return this.$store.state.auth.currentUser.id;
    },
    accountId() {
      return this.$store.state.auth.currentUser.account_id;
    },
  },
  validations: {
    channelName: { required, regexWithoutSpecialCharacters },
    email: { required, email },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        this.loading = true;
        const emailChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            // name: `${this.channelName}-d8d2402a-c698-4f67-b70b-6c433c9753d0`,
            name: `${this.channelName}`,
            channel: {
              type: 'email',
              email: this.email,
            },
          }
        );
        this.inboxId = emailChannel.id;
        await this.$store.dispatch('avonale/createAutomation', {
          inboxId: emailChannel.id,
          userId: this.userLoggedId,
          accountId: this.accountId,
        });

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: emailChannel.id,
          },
        });
      } catch (error) {
        this.showAlert('Não foi possível salvar o canal de Classificados.');
        router.replace({ name: 'settings_inbox_new' });
        if (this.inboxId) {
          this.$store.dispatch('inboxes/delete', this.inboxId);
        }
      }
    },
  },
};
</script>
