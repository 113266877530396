// import { getCookie } from '../../../dashboard/helper/utils';
import avonaleAPIHelper from '../../helper/avonaleAPIHelper';
import { identifierZlink } from '../../helper/utils';
import constants from '../../resources/constants';

import axios from 'axios';
// import store from "../../store/index";
// import { refreshToken } from '../../../../javascript/dashboard/api/avonale/connectService';

const API = axios.create({
  baseURL: constants.whatsAppServerAddress,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    crossdomain: true,
    'Access-Control-Allow-Origin': '*',
  },
});

API.interceptors.request.use(avonaleAPIHelper, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// let isAlreadyFetchingAccessToken = false;
// let subscribers = [];

// const onAccessTokenFetched = accessToken => {
//   subscribers = subscribers.filter(callback => callback(accessToken));
// };

// const addSubscriber = callback => {
//   subscribers.push(callback);
// };

// API.interceptors.response.use(
//   response => response,
//   error => {
//     const { config, response } = error;
//     const originalRequest = config;
//     if (response && response.status === 401) {
//       if (!isAlreadyFetchingAccessToken) {
//         isAlreadyFetchingAccessToken = true;
//         const refresh = getCookie('avonaleRefreshToken');
//         const token = getCookie('avonaleToken');

//         const domain = process.env.VUE_APP_EMBEDED_DOMINIO_URL
//           ? `; domain=${process.env.VUE_APP_EMBEDED_DOMINIO_URL}`
//           : '';
//         refreshToken(refresh, token)
//           .then(data => {
//             isAlreadyFetchingAccessToken = false;
//             // Update avonale in Cookie
//             document.cookie = `avonaleToken=${data.token}; path=/` + domain;
//             document.cookie =
//               `avonaleRefreshToken=${data.refreshToken}; path=/` + domain;
//             document.cookie =
//               `avonaleExpiresAt=${data.expiresAt}; path=/` + domain;

//             onAccessTokenFetched(data.token);
//           })
//           .catch(() => {
//             document.cookie = `loginPage=true; path=/` + domain;
//           });
//       }

//       const retryOriginalRequest = new Promise(resolve => {
//         addSubscriber(accessToken => {
//           originalRequest.headers.Authorization = `Bearer ${accessToken}`;
//           resolve(API(originalRequest));
//         });
//       });

//       return retryOriginalRequest;
//     }

//     return Promise.reject(error);
//   }
// );

// let groupId;

// setInterval(() => {
//   groupId = getCookie('avonaleGrupoId');
// }, 1000);

async function createConnectionWhatsapp(accountId, inboxId, context, userId) {
  return API.post(
    `/WhatsApp/Container/Inserir/${identifierZlink}/${accountId}/${inboxId}/${userId}`,
    {},
    { context }
  );
}

async function updateConnectionWhatsapp(accountId, inboxId, context, userId) {
  return API.post(
    `/WhatsApp/Connect/${identifierZlink}/${accountId}/${inboxId}/${userId}`,
    {},
    { context }
  );
}

async function deleteConnectionWhatsapp(inboxId, context) {
  return API.delete(`/WhatsApp/Desconectar/${identifierZlink}/${inboxId}`, {
    context,
  });
}

async function getWhatsAppStatus(inboxId) {
  const response = await API.get(
    `/WhatsApp/Status/${identifierZlink}/${inboxId}`
  );
  return response.data;
}
export {
  createConnectionWhatsapp,
  updateConnectionWhatsapp,
  deleteConnectionWhatsapp,
  getWhatsAppStatus,
};
