import avonaleAPIHelper from '../../../../helper/avonaleAPIHelper';
import { identifierZlink } from '../../../../helper/utils';
import constants from '../../../../resources/constants';

import axios from 'axios';

const API = axios.create({
  baseURL: constants.whatsAppServerAddress,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    crossdomain: true,
    'Access-Control-Allow-Origin': '*',
  },
});

API.interceptors.request.use(avonaleAPIHelper, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

async function ListStatusInboxes(accountId) {
  return API.get(
    `/WhatsApp/Status/ListarStatusConexoes/${accountId}/${identifierZlink}`
  );
}
async function ReconnectInstance({ nomeDaInstancia, providerId }) {
  return API.post(
    `/WhatsApp/Container/ReconectarInstancia/${nomeDaInstancia}/${providerId}`
  );
}

export { ListStatusInboxes, ReconnectInstance };
