const Bot = () => import('./Index.vue');
import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/widget'),
      roles: ['administrator'],
      component: SettingsContent,
      props: {
        headerTitle: '',
        icon: 'bot',
        showNewButton: false,
      },

      children: [
        {
          path: '',
          name: 'widget',
          component: Bot,
          meta: {
            permissions: ['administrator'],
          },
        },
      ],
    },
  ],
};
