import actions from './actions';
import mutations from './mutations';

const state = {
  inbox: null,
  inboxes: [],
  unsubscriber: null,
  inboxCounter: 0,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
