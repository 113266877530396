<template>
  <div>
    <div class="container-qrcode-list">
      <div>
        <div class="font-normal text-xl pb-2.5">
          Para vincular seu contato com o Zlink:
        </div>
        <ol class="font-normal text-sm pb-2.5">
          <li>1 - Abra o Whatsapp no seu celular.</li>
          <li>
            2 - Toque em "Mais opções" ou "Configurações", depois em "Aparelhos
            conectados".
          </li>
          <li>3 - Toque em "Conectar um aparelho".</li>
          <li>4 - Aponte a câmera do seu celular e leia o Qrcode ao lado.</li>
        </ol>
      </div>
      <div
        v-if="
          !qrCodeImg ||
          estadoDaConexao === 'isLogged' ||
          estadoDaConexao === 'isReconnect' ||
          !estadoDaConexao ||
          refreshQrCode ||
          loadingUpdateInbox ||
          loadingCreateInbox ||
          loadingStatus
        "
        class="qrcode"
      >
        <img
          class="qrcode-fake"
          src="~dashboard/assets/images/qrCodeFake.png"
          alt="qrcode"
        />
        <div
          v-if="
            loadingUpdateInbox ||
            !qrCodeImg ||
            loadingCreateInbox ||
            !estadoDaConexao ||
            estadoDaConexao === 'isReconnect' ||
            loadingStatus
          "
          class="qrcode-bola"
        >
          <svg class="qrcode-loading" viewBox="0 0 24 24" />
          <span>Carregando...</span>
        </div>
        <div
          v-else-if="
            refreshQrCode && !loadingUpdateInbox && !loadingCreateInbox
          "
          class="qrcode-bola"
          style="cursor: pointer"
          @click="updateQrCode"
        >
          <img src="~dashboard/assets/images/Shape.svg" />
          <span>Clique para recarregar</span>
        </div>
        <div v-else-if="estadoDaConexao === 'isLogged'" class="qrcode-bola">
          <img src="~dashboard/assets/images/Vector.svg" />
          <span>Conectado</span>
        </div>
      </div>
      <div v-else class="qrcode">
        <img :src="qrCodeImg" style="width: 100%; height: 100%" alt="qrcode" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inboxId: {
      required: true,
    },
    accountId: {
      required: true,
    },
    loadingCreateInbox: {
      default: false,
    },
    pushAddAgents: {
      default: false,
    },
    isDisconnected: {
      default: false,
    },
    isConnecting: {
      default: false,
    },
    loadingStatus: {
      default: false,
    },
  },
  data() {
    return {
      qrCodeImg: '',
      loadingUpdateInbox: false,
      refreshQrCode: false,
      estadoDaConexao: undefined,
      loadingIsReconnect: false,
      statusSucess: [
        'isConnected',
        'isLogged',
        'qrReadSuccess',
        'Connected',
        'open',
      ],
      statusError: [
        'tokenRemoved',
        'disconnected',
        'Disconnected',
        'notLogged',
        'isLogout',
        'qrReadFail',
        'qrReadError',
        'browserClose',
        'close',
        'desconnectedMobile',
        'connecting',
      ],
    };
  },
  computed: {
    userLoggedId() {
      return this.$store.state.auth.currentUser.id;
    },
  },
  watch: {
    '$store.state.inboxFirestore.inbox.QrCode': {
      handler(newVal) {
        if (newVal) {
          this.qrCodeImg = this.base64ToImage(newVal);
          if (this.$route.name === 'settings_inboxes_page_channel') {
            this.$emit('enableButton');
          }
        }
        setTimeout(() => {
          this.setRefreshQrCode();
        }, 30000);
      },
      deep: true,
      immediate: true,
    },
    '$store.state.inboxFirestore.inbox.EstadoDaConexao': {
      handler(newVal) {
        if (
          (newVal === 'qrReadSuccess' || newVal === 'open') &&
          this.pushAddAgents
        ) {
          this.pushAddAgents();
        }
        if (newVal === 'isReconnect') {
          this.estadoDaConexao = newVal;
        } else if (this.statusError.includes(newVal)) {
          this.estadoDaConexao = 'notLogged';
        } else if (this.statusSucess.includes(newVal)) {
          this.estadoDaConexao = 'isLogged';
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    if (
      this.$route.name !== 'settings_inboxes_page_channel' &&
      this.isDisconnected
    ) {
      await this.triggerConnectionUpdate();
    } else if (
      this.$route.name !== 'settings_inboxes_page_channel' &&
      this.isConnecting
    ) {
      this.updateQrCode();
    }
    await this.$store.dispatch(
      'inboxFirestore/startFirestoreRealtimeTracking',
      {
        inboxId: this.inboxId,
        multiplesInboxes: false,
      }
    );
  },
  destroyed() {
    if (this.$route.name !== 'settings_inbox_list') {
      this.stopFirestore();
      this.$store.dispatch('inboxFirestore/clearInbox');
    }
  },
  methods: {
    setRefreshQrCode() {
      if (
        this.$store.state.inboxFirestore.inbox &&
        this.$store.state.inboxFirestore.inbox.UltimaAlteracaoDoQrCode
      ) {
        let dataAtual = new Date();
        let dataAtualEmSegundos = Math.floor(dataAtual.getTime() / 1000);
        let ultimaAtualizacao =
          this.$store.state.inboxFirestore.inbox.UltimaAlteracaoDoQrCode
            .seconds;
        this.refreshQrCode =
          dataAtualEmSegundos - ultimaAtualizacao >= 30 &&
          this.statusError.includes(this.estadoDaConexao);
      }
    },
    stopFirestore() {
      this.$store.dispatch('inboxFirestore/stopFirestoreRealtimeTracking');
      this.$store.dispatch('inboxFirestore/finishFirestoreRealtimeTracking');
    },
    base64ToImage(base64StringFromURL) {
      var image = new Image();
      return (image.src = base64StringFromURL);
    },
    async updateQrCode() {
      this.refreshQrCode = false;
      this.loadingUpdateInbox = true;

      if (this.estadoDaConexao === undefined) {
        const inbox = await this.fetchInboxData();
        if (this.isConnectionError(inbox.EstadoDaConexao)) {
          this.updateQRCode();
        }
      } else if (this.isConnectionError(this.estadoDaConexao)) {
        const qrCodeUpdated = this.$store.state.inboxFirestore.inbox.QrCode;
        this.updateQRCode(qrCodeUpdated);
      }

      this.setLoadingUpdateInboxTimeout();
    },

    isConnectionError(connectionStatus) {
      return this.statusError.includes(connectionStatus);
    },

    isConnectionDisconnected(connectionStatus) {
      return connectionStatus.toLowerCase() === 'disconnected';
    },

    setLoadingUpdateInboxTimeout() {
      setTimeout(() => {
        this.loadingUpdateInbox = false;
      }, 2000);
    },
    async fetchInboxData() {
      return await this.$store.dispatch('inboxFirestore/findInboxFirestore', {
        inboxId: this.inboxId,
      });
    },

    updateQRCode(qrCode) {
      this.qrCodeImg = this.base64ToImage(qrCode);
    },

    async triggerConnectionUpdate() {
      await this.$store.dispatch('avonale/postUpdateConnectionWhatsapp', {
        accountId: this.accountId,
        inboxId: this.inboxId,
        context: this,
        userId: this.userLoggedId,
      });
    },
  },
};
</script>
<style scoped>
.qrcode {
  width: 164px;
  height: 164px;
  position: relative;
  min-width: 164px;
  max-width: 164px;
}

.qrcode-fake {
  opacity: 0.3;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.qrcode-bola {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  top: 10px;
  right: 11px;
  z-index: 9999;
  position: absolute;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #3c4858;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.qrcode-loading {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}
.container-qrcode-list ol {
  list-style-type: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container-qrcode-list {
  display: flex;
  gap: 2rem;
}

@media (min-width: 360px) and (max-width: 785px) {
  .container-qrcode-list {
    flex-direction: column;
    margin-bottom: 1rem;
    gap: 1rem;
  }
}
</style>
