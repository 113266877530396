import auth from "../api/auth";

export default function (config) {
    try {
      const {
        'access-token': accessToken,
        client,
        uid,
      } = auth.getAuthData();
  
      config.headers["AuthorizationUid"] = uid
      config.headers["AuthorizationClient"] = client
      config.headers["AuthorizationAccessToken"] = accessToken
    } catch(err) {
      console.log(err)
      console.error("Failed to set authorization")
    }
    return config;
  }