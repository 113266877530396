import {
  createConnectionWhatsapp,
  updateConnectionWhatsapp,
  deleteConnectionWhatsapp,
  getWhatsAppStatus,
} from '../../../api/avonale/whatsappService';
import { login } from '../../../api/avonale/connectService';
import {
  createAutomation,
  deleteAutomation,
} from '../../../api/avonale/chatpierService';
import {
  getTelephonyUser,
  openCall,
} from '../../../api/avonale/telephonyService';
import { getCookie } from '../../../helper/utils';

export default {
  async createAutomation(_, { inboxId, userId, accountId }) {
    return createAutomation(inboxId, userId, accountId);
  },
  async deleteAutomation(_, { inboxId, userId, accountId }) {
    return deleteAutomation(inboxId, userId, accountId);
  },
  async postCreateConnectionWhatsapp(
    _,
    { accountId, inboxId, context, userId }
  ) {
    return createConnectionWhatsapp(accountId, inboxId, context, userId);
  },
  async postUpdateConnectionWhatsapp(
    _,
    { accountId, inboxId, context, userId }
  ) {
    return updateConnectionWhatsapp(accountId, inboxId, context, userId);
  },
  async deleteConnectionWhatsapp(_, { inboxId, context }) {
    return deleteConnectionWhatsapp(inboxId, context);
  },

  async getWhatsAppStatus(_, { inboxId, context }) {
    const response = await getWhatsAppStatus(inboxId, context);
    return response.data;
  },
  async login(_, { username, password }) {
    return login(username, password);
  },
  setInboxBanner({ commit }, { exibirBannerStatusDisconnected, id }) {
    commit('SET_INBOX_BANNER', {
      exibirBannerStatusDisconnected,
      id,
    });
  },
  async getTelephonyAll() {
    const userIdAvonale = getCookie('avonaleUserId');
    const response = await getTelephonyUser(userIdAvonale);
    return response.data;
  },
  async postOpenCall(_, { userAvonaleId, telephonyId, destinationNumber }) {
    return openCall(userAvonaleId, telephonyId, destinationNumber);
  },
  setProvidersTelephony({ commit }, data) {
    commit('SET_PROVIDERS_TELEPHONY', data);
  },
};
