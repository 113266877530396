import {
  format,
  isSameYear,
  fromUnixTime,
  formatDistanceToNow,
} from 'date-fns';
import { ptBR, pt, it, de, enUS, es, fr, nl } from 'date-fns/locale';

export default {
  methods: {
    messageStamp(time, dateFormat = 'h:mm a') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },

    messageTimestamp(time, dateFormat = 'MMM d, yyyy') {
      const messageTime = fromUnixTime(time);
      const now = new Date();
      const messageDate = format(messageTime, dateFormat, { locale: ptBR });
      if (!isSameYear(messageTime, now)) {
        return format(messageTime, 'd LLL y, h:mm a', { locale: ptBR });
      }
      return messageDate;
    },
    dynamicTime(time) {
      const currentLangauge = this.$root.$i18n.locale;

      const allLeguanges = {
        pt_BR: ptBR,
        pt: pt,
        it: it,
        de: de,
        enUS: enUS,
        es: es,
        fr: fr,
        nl: nl,
      };
      const unixTime = fromUnixTime(time);
      return `${formatDistanceToNow(unixTime, {
        addSuffix: true,
        locale: allLeguanges[currentLangauge],
      })}`
        .replace('há cerca de', 'há')
        .replace('há menos de um minuto', 'Agora');
    },
    dateFormat(time, dateFormat = 'MMM d, yyyy') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    shortTimestamp(time, withAgo = false) {
      // This function takes a time string and converts it to a short time string
      // with the following format: 1m, 1h, 1d, 1mo, 1y
      // The function also takes an optional boolean parameter withAgo
      // which will add the word "ago" to the end of the time string
      const suffix = withAgo ? ' ago' : '';
      const timeMappings = {
        'less than a minute ago': 'now',
        'a minute ago': `1m${suffix}`,
        'an hour ago': `1h${suffix}`,
        'a day ago': `1d${suffix}`,
        'a month ago': `1mo${suffix}`,
        'a year ago': `1y${suffix}`,
      };
      // Check if the time string is one of the specific cases
      if (timeMappings[time]) {
        return timeMappings[time];
      }
      const convertToShortTime = time
        .replace(/about|over|almost|/g, '')
        .replace(' minute ago', `m${suffix}`)
        .replace(' minutes ago', `m${suffix}`)
        .replace(' hour ago', `h${suffix}`)
        .replace(' hours ago', `h${suffix}`)
        .replace(' day ago', `d${suffix}`)
        .replace(' days ago', `d${suffix}`)
        .replace(' month ago', `mo${suffix}`)
        .replace(' months ago', `mo${suffix}`)
        .replace(' year ago', `y${suffix}`)
        .replace(' years ago', `y${suffix}`);
      return convertToShortTime;
    },
    abbreviateDistance(distance) {
      const locale = this.$root.$i18n.locale;

      const abbreviations = {
        pt: { days: 'd', hours: 'h', minutes: 'min', years: 'a', months: 'm' },
        pt_BR: { days: 'd', hours: 'h', minutes: 'min', years: 'a', months: 'm' },
        enUS: { days: 'd', hours: 'h', minutes: 'min', years: 'y', months: 'm' },
        es: { days: 'd', hours: 'h', minutes: 'min', years: 'a', months: 'm' },
      };
    
      const abbr = abbreviations[locale] || abbreviations.enUS;
      const patterns = {
        pt: { days: /dia(s)?/g, hours: /hora(s)?/g, minutes: /minuto(s)?/g, years: /ano(s)?/g, months: /(mês|meses)/g,},
        pt_BR: { days: /dia(s)?/g, hours: /hora(s)?/g, minutes: /minuto(s)?/g, years: /ano(s)?/g, months: /mês|meses/g,},
        enUS: { days: /day(s)?/g, hours: /hour(s)?/g, minutes: /minute(s)?/g, years: /year(s)?/g, months: /month(s)?/g },
        es: { days: /día(s)?/g, hours: /hora(s)?/g, minutes: /minuto(s)?/g, years: /año(s)?/g, months: /mes(es)?/g },
      };
    
      const pattern = patterns[locale] || patterns.enUS;
      return distance
        .replace(pattern.days, abbr.days)
        .replace(pattern.hours, abbr.hours)
        .replace(pattern.minutes, abbr.minutes)
        .replace(pattern.years, abbr.years)
        .replace(pattern.months, abbr.months);
    }
  },
};
