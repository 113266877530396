import Firebase from "firebase/app";
import "firebase/firestore";
import configFirestore from "../../resources/firestore/configFirestore";

Firebase.initializeApp(configFirestore);

function firestoreCreateContext() {
  return Firebase.firestore();
}

export { firestoreCreateContext };