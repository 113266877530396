const mutations = {
  SET_TOKEN(state, data) {
    state.user = data;
  },
  SET_INBOX_BANNER(state, { exibirBannerStatusDisconnected, id }) {
    state.inboxBanner.exibirBannerStatusDisconnected = exibirBannerStatusDisconnected;
    state.inboxBanner.id = id;
  },
  SET_PROVIDERS_TELEPHONY(state, data) {
    state.telephonyAll = data.map(item => ({
      id: item.telefoniaId,
      name: item.telefoniaNome,
    }));
  },
};

export default mutations;
