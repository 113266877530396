import { firestoreCreateContext } from '../../../api/firestore/firestoreService';
import { identifierZlink } from '../../../helper/utils';

export default {
  async startFirestoreRealtimeTracking(
    { commit },
    { inboxId, multiplesInboxes }
  ) {
    if (inboxId) {
      const dbContextFirestore = firestoreCreateContext();

      const collectionInboxes = dbContextFirestore
        .collection('key')
        .doc(identifierZlink)
        .collection('inboxes');

      let docInbox;

      docInbox = collectionInboxes.doc(`inbox_${inboxId}`);

      const unsubscribe = docInbox.onSnapshot(doc => {
        if (doc && doc.data()) {
          // console.log("docInbox", doc.data());
          let inboxFirestore = {
            ...doc.data(),
            id: doc.id,
          };
          if (!multiplesInboxes) {
            commit('UPDATE_INBOX', inboxFirestore);
          } else {
            commit('UPDATE_INBOXES', inboxFirestore);
          }
        }
      });
      const unsubscriber = {
        unsubscribe,
      };

      commit('SET_UNSUBSCRIBER', unsubscriber);
    }
  },
  async findInboxFirestore(_, { inboxId }) {
    if (inboxId) {
      const dbContextFirestore = firestoreCreateContext();
      const collectionInboxes = dbContextFirestore
        .collection('key')
        .doc(identifierZlink)
        .collection('inboxes');
      const meuDoc = collectionInboxes.doc(`inbox_${inboxId}`);
      const get = await meuDoc.get();
      return get.data();
    }
    return null;
  },
  stopFirestoreRealtimeTracking({ commit, state }) {
    if (state.unsubscriber) {
      state.unsubscriber.unsubscribe();
      commit('SET_UNSUBSCRIBER', null);
    }
  },
  finishFirestoreRealtimeTracking() {
    firestoreCreateContext().terminate();
  },
  setInbox({ commit }, inbox) {
    commit('SET_INBOX', inbox);
  },
  clearInbox({ commit }) {
    commit('CLEAR_INBOX');
  },
  clearInboxes({ commit }) {
    commit('CLEAR_INBOXES');
  },
};
