import resources from '../resources';

let firebaseConfig = {
  apiKey: '',
  authDomain: '',
  databaseURL: '',
  projectId: '',
  storageBucket: '',
  messagingSenderId: '',
  appId: '',
  measurementId: '',
};

switch (resources.version.name) {
  case '999.0.0': // Dev
    firebaseConfig.apiKey = 'AIzaSyDeTvff3xe2YYWK5El2N6Ud1h7NipB5kDU';
    firebaseConfig.authDomain = 'zlink-dev.firebaseapp.com';
    firebaseConfig.databaseURL =
      'https://zlink-dev-default-rtdb.firebaseio.com';
    firebaseConfig.projectId = 'zlink-dev';
    firebaseConfig.storageBucket = 'zlink-dev.appspot.com';
    firebaseConfig.messagingSenderId = '65057656203';
    firebaseConfig.appId = '1:65057656203:web:c042a03480770aa8dd738e';
    firebaseConfig.measurementId = 'G-XGQCZDL7GP';
    break;

  default:
    // Prod 1.0, 1.2.0, 1.3.0, etc
    firebaseConfig.apiKey = 'AIzaSyDrnqTBhx18nXTGSpcxaBIUc9JdUeT3-5o';
    firebaseConfig.authDomain = 'zlink-9b4d4.firebaseapp.com';
    firebaseConfig.databaseURL =
      'https://zlink-9b4d4-default-rtdb.firebaseio.com';
    firebaseConfig.projectId = 'zlink-9b4d4';
    firebaseConfig.storageBucket = 'zlink-9b4d4.appspot.com';
    firebaseConfig.messagingSenderId = '406167255647';
    firebaseConfig.appId = '1:406167255647:web:d3eb9c8e55e9905ae439ea';
    firebaseConfig.measurementId = 'G-G7GTVKJTV7';
    break;
}
export default firebaseConfig;
