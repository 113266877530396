import axios from 'axios';
import constants from '../../resources/constants'
import avonaleAPIHelper from '../../helper/avonaleAPIHelper';


const API = axios.create({
  baseURL: constants.autenticacaoServerAddress,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    crossdomain: true,
    "Access-Control-Allow-Origin": "*",
  },
})

API.interceptors.request.use(avonaleAPIHelper, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

async function login(username, password) {
  return API.post("/connect/token", {
    username,
    password,
    plataform: 2,
  })
}

async function refreshToken(refreshToken, token) {
  const response = await API.post("/connect/refreshtoken", {
    refreshToken,
    token,
  })
  return response.data;
}

async function loginFirebaseToken(token) {
  API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const response = await API.post("/CustomToken/Generate");
  return response.data;
}

export { login, refreshToken, loginFirebaseToken };