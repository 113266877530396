<template>
  <div
    class="small-9 columns border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-grow-0 flex-shrink-0"
  >
    <div>
      <div class="font-normal text-xl pb-2.5">{{ textTitle }}</div>
      <div class="font-normal text-sm pb-2.5">
        Integre com canal ZLink e comece a ajudar seus clientes.
      </div>
      <form class="row" @submit.prevent="actionButton()">
        <div class="md:w-8/12">
          <label :class="{ error: $v.channelName.$error }">
            {{ $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.LABEL') }}
            <input
              v-model.trim="channelName"
              :disabled="configNumber"
              type="text"
              :placeholder="
                $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
              "
              @blur="$v.channelName.$touch"
            />
            <span v-if="$v.channelName.$error" class="message">{{
              $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.ERROR')
            }}</span>
          </label>
        </div>
        <config-number
          v-if="configNumber"
          :account-id="userId"
          :inbox-id="inboxId"
          :loading-create-inbox="loadingCreateInbox"
          :push-add-agents="pushAddAgents"
          @enableButton="enableButton"
        />

        <div class="medium-12 columns">
          <woot-submit-button
            :loading="uiFlags.isCreating"
            :button-text="textButton"
            :disabled="disabledButton"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import ConfigNumber from './components/ConfigNumber.vue';
import router from '../../../../../index';
import constants from '../../../../../../resources/constants';
import { regexWithoutSpecialCharacters } from '../../../../../../helper/regex';
import { identifierZlink } from '../../../../../../helper/utils';

export default {
  components: {
    ConfigNumber,
  },
  mixins: [alertMixin],
  data() {
    return {
      inboxId: undefined,
      configNumber: false,
      channelName: '',
      loadingCreateInbox: true,
      disabledButton: false,
    };
  },
  computed: {
    textTitle() {
      return this.configNumber
        ? `Conectar canal com Whatsapp`
        : 'Canal do Zlink';
    },
    textButton() {
      return this.configNumber ? 'Pular' : 'Criar Canal';
    },
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
    userId() {
      return this.$store.state.auth.currentUser.account_id;
    },
    userLoggedId() {
      return this.$store.state.auth.currentUser.id;
    },
  },
  validations: {
    channelName: { required, regexWithoutSpecialCharacters },
  },
  methods: {
    enableButton() {
      this.disabledButton = false;
    },
    actionButton() {
      return this.configNumber ? this.pushAddAgents() : this.createChannel();
    },
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        this.disabledButton = true;
        const apiChannel = await this.$store.dispatch('inboxes/createChannel', {
          name: this.channelName,
          channel: {
            webhook_url: `${constants.whatsAppServerAddress}/WhatsApp/SendMessage/${identifierZlink}`,
            type: 'api',
          },
        });
        this.inboxId = apiChannel.id;
        this.configNumber = true;
        await this.$store.dispatch('avonale/postCreateConnectionWhatsapp', {
          accountId: this.userId,
          inboxId: this.inboxId,
          token: this.tokenAvonale,
          context: this,
          userId: this.userLoggedId,
        });
        this.loadingCreateInbox = false;
      } catch (error) {
        this.showAlert('Não foi possível salvar o canal de ZLink');
        router.replace({ name: 'settings_inbox_new' });
        if (this.inboxId) {
          this.$store.dispatch('inboxes/delete', this.inboxId);
        }
      }
    },
    pushAddAgents() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: this.inboxId,
          },
        });
      } catch (error) {
        this.showAlert(
          'Não foi possível criar a caixa de entrada com o canal ZLink'
        );
      }
    },
  },
};
</script>

<style scoped>
input:disabled {
  background-color: #efefef !important;
}
.wizard-body {
  height: auto !important;
}
</style>
