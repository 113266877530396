/* eslint-disable no-useless-catch */
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../../mutation-types';
import _ from 'lodash';
import { firestoreCreateContext } from '../../../api/firestore/firestoreService';
import { identifierZlink } from '../../../helper/utils';
import {
  createCampaign,
  getAllCampaigns,
  removeCampaigns,
  updateCampaign,
  updateStatusCampaign,
  CreateProviderCampaign,
  getAllProviders,
  removeProvider,
  getMetricsCampaigns,
} from './Api/campaignsWhatsapp';
import { ListStatusInboxes, ReconnectInstance } from './Api/zlink';
import router from '../../../routes';

const campaignDraft = {
  campanhaId: null,
  nome: null,
  intervaloRepetir: null,
  conteudo: [],
  numeroContatoViewModel: {
    tipoNumero: null,
    conteudoNumeroContato: '[]',
  },
  recorrencia: null,
  horarioInicio: null,
  horarioFim: null,
  providerId: null,
  inicioCampanha: null,
  fimCampanha: null,
  status: null,
  intervaloMensagem: null,
  botCampanhaId: null,
  accountId: null,
};

export const state = {
  uiFlags: {
    isFetchingItem: false,
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isExecuting: false,
  },
  records: [],
  listInboxesStatus: [],
  inboxes: [],
  providers: [],
  metrics: [],
  inbox: null,
  unsubscriber: null,
  inboxCounter: 0,
  searchText: '',
  campaignDraft: JSON.parse(JSON.stringify(campaignDraft)),
};

export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getDraft($state) {
    return $state.campaignDraft;
  },
  ListStatusInboxes: _state => {
    return _state.listInboxesStatus;
  },
  getAllProviders: _state => {
    return _state.providers;
  },
  getAllCampaigns: _state => {
    return _state.records;
  },
  getSearchText: _state => {
    return _state.searchText;
  },
  getMetricsCampaigns: _state => {
    return _state.metrics;
  },
};

export const actions = {
  activeUiFlagsIsDeleting({ commit }, isDeleting) {
    commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isDeleting: isDeleting });
  },
  setSearchText({ commit }, searchText) {
    commit(types.SET_SEARCH_TEXT, searchText);
  },
  setDraft({ commit }, payload) {
    var draft = {
      campanhaId: payload.campanhaId,
      nome: payload.nome,
      intervaloRepetir: payload.intervaloRepetir,
      conteudo: payload.conteudo,
      recorrencia: payload.recorrencia,
      horarioInicio: payload.horarioInicio,
      horarioFim: payload.horarioFim,
      status: payload.status,
      intervaloMensagem: payload.intervaloMensagem,
      inicioCampanha: payload.inicioCampanha,
      fimCampanha: payload.fimCampanha,
      botCampanhaId: payload.botCampanhaId,
      accountId: payload.accountId,
      providerId: payload.providerId,
    };

    if (payload.numeroContatoViewModel)
      draft.numeroContatoViewModel = {
        tipoNumero: payload.numeroContatoViewModel?.tipoNumero,
        conteudoNumeroContato:
          payload.numeroContatoViewModel?.conteudoNumeroContato ?? undefined,
      };

    if (draft.status !== 'Rascunho') {
      if (draft.inicioCampanha) draft.status = 'Agendada';
      else draft.status = 'EmAndamento';
    }

    draft = _.omitBy(draft, v => !v);

    draft.accountId = router.history.current.params.accountId;

    commit(types.SET_DRAFT, draft);
  },
  async saveCampaign({ commit, state: $state }) {
    commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isCreating: true });
    try {
      const draft = $state.campaignDraft;
      if (
        draft.numeroContatoViewModel.tipoNumero === 'NumerosBrutos' ||
        draft.numeroContatoViewModel.tipoNumero === 2
      )
        draft.numeroContatoViewModel.conteudoNumeroContato = JSON.parse(
          draft.numeroContatoViewModel.conteudoNumeroContato
        ).join(';');

      if (draft.campanhaId) {
        await updateCampaign(draft);
      } else await createCampaign(draft);
    } catch (error) {
      throw error;
    } finally {
      commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isCreating: false });
    }
  },
  clearDraft({ commit }) {
    commit(types.CLEAR_DRAFT);
  },
  clearMetrics({ commit }) {
    commit(types.CLEAR_METRICS);
  },
  async getCampaigns({ commit }) {
    commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isFetching: true });
    try {
      const accountId = router.history.current.params.accountId;
      const response = await getAllCampaigns(accountId);
      commit(types.SET_WHATSAPP_CAMPAIGNS, response.data.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isFetching: false });
    }
  },

  async getMetrics({ commit }, campaignId) {
    commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isFetching: true });
    try {
      const response = await getMetricsCampaigns(campaignId);
      commit(types.SET_METRICS_WHATSAPP_CAMPAIGNS, response.data.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isFetching: false });
    }
  },

  async ListInboxes({ commit }) {
    commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isFetching: true });
    try {
      const accountId = router.history.current.params.accountId;
      const response = await ListStatusInboxes(accountId);
      commit(types.SET_INBOXES_STATUS, response.data.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isFetching: false });
    }
  },
  async ReconnectInstance({ commit }, { nomeDaInstancia, providerId }) {
    try {
      await ReconnectInstance({ nomeDaInstancia, providerId });
      commit(types.RECONECT_INSTANCE, response.data.data);
    } catch (error) {
      // Ignore error
    }
  },
  async getListByProviders({ commit }) {
    commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isFetching: true });
    try {
      const accountId = router.history.current.params.accountId;
      const response = await getAllProviders(accountId);
      commit(types.SET_PROVIDER, response.data.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isFetching: false });
    }
  },
  async updateCampaigns({ commit }, campaignObj) {
    commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isUpdating: true });
    try {
      const response = await updateCampaign(campaignObj);
      commit(types.EDIT_WHATSAPP_CAMPAIGN, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isUpdating: false });
    }
  },
  async updateStatusCampaigns({ commit }, campaignObj) {
    commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isUpdating: true });
    try {
      await updateStatusCampaign(campaignObj);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isUpdating: false });
    }
  },
  async createProvider({ commit }, providerObj) {
    commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isCreating: true });
    try {
      const response = await CreateProviderCampaign(providerObj);
      return response.data.data;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isCreating: false });
    }
  },
  async deleteCampaigns({ commit }, campaignId) {
    commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isDeleting: true });
    try {
      await removeCampaigns(campaignId);
      commit(types.DELETE_WHATSAPP_CAMPAIGN, campaignId);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isDeleting: false });
    }
  },

  async deleteProvider({ commit }, providerId) {
    commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isDeleting: true });
    try {
      await removeProvider(providerId);
      commit(types.DELETE_PROVIDER, providerId);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG, { isDeleting: false });
    }
  },

  // Firebase
  async startFirestoreRealtimeTracking(
    { commit },
    { providerId, multiplesInboxes }
  ) {
    if (providerId) {
      const dbContextFirestore = firestoreCreateContext();

      const collectionInboxes = dbContextFirestore
        .collection('key')
        .doc(identifierZlink)
        .collection('providers');

      let docInbox;

      docInbox = collectionInboxes.doc(providerId);
      const unsubscribe = docInbox.onSnapshot(doc => {
        if (doc && doc.data()) {
          // console.log("docInbox", doc.data());
          let inboxFirestore = {
            ...doc.data(),
            id: doc.id,
          };
          if (!multiplesInboxes) {
            commit('UPDATE_INBOX', inboxFirestore);
          } else {
            commit('UPDATE_INBOXES', inboxFirestore);
          }
        }
      });
      const unsubscriber = {
        unsubscribe,
      };

      commit('SET_UNSUBSCRIBER', unsubscriber);
    }
  },

  stopFirestoreRealtimeTracking({ commit, state }) {
    if (state.unsubscriber) {
      state.unsubscriber.unsubscribe();
      commit('SET_UNSUBSCRIBER', null);
    }
  },
  finishFirestoreRealtimeTracking() {
    firestoreCreateContext().terminate();
  },
  setInbox({ commit }, inbox) {
    commit('SET_INBOX', inbox);
  },
  clearInbox({ commit }) {
    commit('CLEAR_INBOX');
  },
  clearInboxes({ commit }) {
    commit('CLEAR_INBOXES');
  },
};

export const mutations = {
  [types.SET_WHATSAPP_CAMPAIGNS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_DRAFT]: ($state, payload) => {
    Object.assign($state.campaignDraft, payload);
  },
  [types.SET_INBOXES_STATUS]: ($state, payload) => {
    $state.listInboxesStatus = payload;
  },
  [types.SET_PROVIDER]: ($state, payload) => {
    $state.providers = payload;
  },
  [types.SET_METRICS_WHATSAPP_CAMPAIGNS]: ($state, payload) => {
    $state.metrics = payload;
  },
  [types.SET_SEARCH_TEXT]: ($state, searchText) => {
    $state.searchText = searchText;
  },
  [types.CLEAR_DRAFT]: $state => {
    $state.campaignDraft = JSON.parse(JSON.stringify(campaignDraft));
  },
  [types.CLEAR_METRICS]: $state => {
    $state.campaignDraft = [];
  },
  [types.DELETE_PROVIDER]: ($state, providerId) => {
    if ($state.campaignDraft.providerId === providerId)
      $state.campaignDraft.providerId = null;
  },
  SET_INBOX(state, inbox) {
    state.inbox = inbox;
  },
  SET_UNSUBSCRIBER(state, unsubscriber) {
    state.unsubscriber = unsubscriber;
  },
  UPDATE_INBOX(state, inbox) {
    state.inbox = inbox;
  },
  UPDATE_INBOXES(state, inbox) {
    if (state.inboxes.length > 0) {
      const inboxExists = state.inboxes.some(item => item.id === inbox.id);
      if (inboxExists) {
        state.inboxes.forEach((item, index) => {
          if (item.id === inbox.id) {
            state.inboxes[index] = inbox;
            if (item.EstadoDaConexao !== inbox.EstadoDaConexao) {
              state.inboxCounter++;
            }
          }
        });
      } else {
        state.inboxes.push(inbox);
      }
    } else {
      state.inboxes.push(inbox);
    }
  },
  CLEAR_INBOX(state) {
    state.inbox = null;
  },
  CLEAR_INBOXES(state) {
    state.inboxes = [];
  },
  [types.ADD_WHATSAPP_CAMPAIGN]: MutationHelpers.setSingleRecord,
  [types.SET_WHATSAPP_CAMPAIGNS]: MutationHelpers.set,
  [types.EDIT_WHATSAPP_CAMPAIGN]: MutationHelpers.update,
  [types.DELETE_WHATSAPP_CAMPAIGN]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
