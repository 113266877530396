import {
  getKanbanBoards,
  createKanbanBoard,
  updateKanbanBoard,
  deleteKanbanBoard,
} from '../../../api/avonale/chatpierService';

export default {
  async getKanbanBoards(_, { accountId }) {
    return getKanbanBoards(accountId);
  },
  async createKanbanBoard(_, payload) {
    return createKanbanBoard(payload);
  },
  async updateKanbanBoard(_, payload) {
    return updateKanbanBoard(payload);
  },
  async deleteKanbanBoard(_, { boardId }) {
    return deleteKanbanBoard(boardId);
  },
  setDraggedConversation({ commit }, conversation){
    commit('SET_DRAGGED_CONVERSATION', conversation)
  },
  setDraggedConversationGroup({ commit }, {type, id}){
    commit('SET_DRAGGED_CONVERSATION_GROUP', {type, id})
  },
  setRemoveDraggedConversationFunction({ commit }, removeDraggedConversation){
    commit('SET_REMOVE_DRAGGED_CONVERSATION_FUNCTION', removeDraggedConversation)
  },
  clearAllInfo({ commit }) {
    commit('CLEAR_ALL_INFO');
  },
}